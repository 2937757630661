.containerAbout {
  background-color: rgb(118, 161, 211);
  position: relative;
  height: 60vh;
}

.aboutContianer {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
  padding: 100px 50px 80px;
  margin: auto;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 60vh;
}

.aboutContianer {
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.containerAbout img {
  /* margin-top: 100px; */
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 50vh;
}

.aboutContianer h1{
  color: #fff;
  font-weight: bold;
}

.aboutContianer p {
  width: 83%;
  margin: auto;
  font-weight: bold;
  font-size: 27px;
  color: #fff;
}

/* ********************************media********************************** */
@media (max-width: 768px) {
  .aboutContianer {
    padding: 150px 0px;
  }

  .aboutContianer p {
    width: 90%;
    margin: auto;
    font-weight: 500;
    font-size: 18px;
  }

  .containerAbout img {
    margin-top: 0px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 50%;
  }
}
/* ********************************media********************************** */
