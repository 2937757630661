.dropdown-menu {
  background-color: #f1f1f1 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.navbar_nav__UMzop a:first-child {
  margin-top: 0 !important;
}

.dropdown-menu a {
  /* margin-bottom: 0 !important; */
}

.navbar_navDropDownItem__F3TwM .dropdown-item {
  margin: 0 !important;
}

.fi-gb,
.fi-sa {
  font-size: 22px;
  margin-top: 2px;
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .dropdown-menu {
    background-color: #1457a2 !important;
  }

  .dropdown-menu a {
    background-color: #075380;
    background-color: #49a0cf;
  }
}
