.bg {
  padding: 35px;
  background-color: #4a7db7;
  border-radius: 70px;
  position: relative;
  margin-top: 66px;
}

.header {
  background-color: #fff;
  border-radius: 50px;
  padding: 40px 50px 20px;
}

.aboutcontent p {
  color: #444;
  font-weight: bold;
  text-align: center;
  width: 90%;
  font-size: 19px;
  margin: auto;
  margin-bottom: 30px;
  color: #4a7db7;
  text-shadow: 5px 5px 9px #ddd;
  font-size: 25px;
  margin: auto;
  margin-bottom: 60px;
}

.imgContainer {
  border-radius: 10px;
  border: 1px solid #4a7db7;
  padding: 5px;
}

.imgContainer img {
  width: 100%;
  border-radius: 10px;
}

.featuresContent h3,
.advantagesContent h3 {
  color: #4a7db7;
  text-shadow: 5px 5px 9px #ddd;
}

.advantagesContent h6 {
  text-shadow: 5px 5px 9px #ddd;
  font-size: 21px;
}

.featuresContent p {
  color: #444;
  font-weight: bold;
  width: 90%;
  font-size: 19px;
  margin-bottom: 30px;
  font-weight: 500;
}

.ulcontainer ul {
  padding: 0;
  margin: 0;
  width: 100%;
}

.ulcontainer ul li {
  font-style: italic;
  font-size: 19px;
  font-weight: 500;
}

.testP {
  margin-top: 30px;
  width: 100%;
  font-weight: 500;
  color: #4a7db7;
  font-size: 22px;
  text-shadow: 5px 5px 9px #ddd;
}

@media screen and (max-width: 768px) {
  .bg {
    padding: 0px;
    margin-top: 0;
  }

  .header {
    border-radius: 0px;
    padding: 0px;
    margin-top: 90px;
  }

  .aboutcontent p {
    width: 95%;
    padding: 20px 0px;
    font-size: 19px;
    margin-bottom: 20px;
  }

  .imgContainer {
    margin-bottom: 40px;
  }
}
