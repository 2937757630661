.header {
  /* background-color: #b6c2d2; */
  /* background: linear-gradient(rgba(138, 147, 153),rgba(138, 147, 153)); */
  background: rgb(140, 148, 154);
  background: linear-gradient(
    0deg,
    rgba(140, 148, 154, 1) 0%,
    rgba(236, 236, 237, 1) 33%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 133vh;
  margin-bottom: 100px;
  position: relative;
}

.header .imgBg {
  width: 100%;
  height: 90vh;
}

.headerContent {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header h1 {
  color: #fff;
  text-align: center;
  font-weight: bold;
  width: 60%;
  font-size: 50px;
  width: 70%;
  margin: auto;
}

.imgLogo {
  position: absolute;
  bottom: -2px;
  left: 49%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imgLogo img {
  width: 90%;
}

.imgLogo p {
  color: #000;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 45px;
  text-shadow: 5px 5px 9px #777;
}

/* **************************** START MEDIA QUERY ******************************* */
@media (max-width: 768px) {
  .bg {
    border-radius: 0;
    padding: 5px;
  }

  .header {
    text-align: center;
    border-radius: 0;
  }

  .header h1 {
    font-size: 38px;
    margin-bottom: 0px;
    width: 100%;
  }

  .header p {
    font-size: 17px;
  }

  .header {
    height: 100vh;
  }

  .header .imgBg {
    height: 100vh;
  }

  .imgLogo {
    top: 50%;
  }

  .imgLogo img {
    width: 180% !important;
  }

  .imgLogo p {
    font-size: 33px;
  }
}
/* **************************** END MEDIA QUERY ******************************* */
