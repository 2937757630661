.imgContainer {
  position: relative;
}

.galleryContainer {
  padding: 20px 40px 60px;
  /* background: #82c8bd; */
  background-color: rgb(118, 161, 211);
  border-radius: 0px 0px 60px 60px;
}

.galleryContainer h2 {
  text-align: center;
  color: #fff;
  margin: 0px 0px 30px;
}

.imgContainer img {
  /* width: 96%; */
  width: 100%;
  border-radius: 10px;
  height: 250px;
}

.linkContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: rgba(69, 69, 69, 0.538);
  border-radius: 10px;
  opacity: 0;
  transition: 0.3s;
}

.imgContainer:hover .linkContainer {
  opacity: 1;
}

.imgContainer .icon,
.imgContainer .icon1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #82c8bd;
  height: 40px;
  width: 40px;
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  transition: 0.3s;
}

.linkContainer .icon1:hover {
  opacity: 0;
}
