.navbar {
  padding: 0 !important;
  transition: 0.3s;
  z-index: 9999 !important;
  background-color: #1457a2;
}

.navWithoutBackground {
  background-color: transparent !important;
}

.navBackground.navbar {
  z-index: 9999 !important;
}

.header {
  /* margin-bottom: 65px !important; */
}

.logo {
  height: 100px;
  transition: 0.5s;
  /* position: absolute; */
  /* top: 10px; */
}

.navBackground.navbar .logo {
  height: 60px;
}

.nav {
  width: 100% !important;
  justify-content: flex-end !important;
  z-index: 9999 !important;
  /* background-color: #1457a2 !important; */
  width: 45%;
  border-radius: 0px 0px 20px 20px;
}

.navbarCollapse {
  justify-content: flex-end;
}

.nav a {
  margin: 0px 12px;
  font-weight: 600;
  font-size: 17px;
  padding: 20px;
  color: #fff;
}

.nav a:hover {
  color: #ddd !important;
}

.nav button {
  border: none;
  background: none;
  font-weight: 600;
  color: #fff;
  margin: 0px 6px;
}

.navBackground.navbar .nav button {
  color: #fff;
}
.navBackground.navbar .nav button:hover {
  color: #f1f1f1;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 20px 10px;
    background-color: #1457a2 !important;
  }

  .navbarToggle {
    border: none;
    background-color: #444 !important;
    padding: 4px 9px !important;
  }

  .navbarToggle:focus {
    box-shadow: none !important;
  }

  .nav {
    margin-left: 0px;
    width: 100%;
  }

  .nav a:first-child {
    margin-top: 50px !important;
  }

  .nav a,
  button {
    margin: 0px !important;
    margin-bottom: 32px !important;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd !important;
    border-radius: 5px;
    color: #fff !important;
  }

  button {
    margin-bottom: 0 !important;
  }

  .navBackground.navbar {
    /* padding: 10px 20px !important; */
    box-shadow: none;
  }

  .logo {
    height: 90px;
  }

  .contactUsBtn {
    margin-bottom: 20px !important;
  }

  .navDropDown {
    margin: 0 !important;
  }
}

.navBrand {
  margin: 0 !important;
  padding: 0 !important;
}

.navDropDownItem {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  padding: 10px !important;
  color: #1457a2 !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 5px;
}

.navDropDownItem:hover {
  background: #1457a2 !important;
}
