.bg {
  padding: 35px;
  background-color: #1457a2;
  border-radius: 70px;
  margin-top: -12px;
  
  position: relative;
  margin-top: 120px;
}

.header {
  background-color: #fff;
  border-radius: 50px;

  padding: 0px 50px 20px;
}

.header h1 {
  text-align: center;
  color: #1457a2;
  text-shadow: 5px 5px 9px #ddd;
  padding: 5px 0px 20px;
}

.clientContent {
  background-color: #1457a2;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.clientContent h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 8px 8px 9px #666;
  font-size: 22px;
}

.clientContent img {
  width: 100%;
  border-radius: 10px;
  height: 250px;
}

/* ********************************media********************************** */
@media (max-width: 768px) {
  .bg {
    padding: 0px;
    margin-top: 0;
  }

  .header {
    border-radius: 0px;
    padding: 0px;
    margin-top: 90px;
  }
}
/* ********************************media********************************** */
